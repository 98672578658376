.App {
  text-align: center;
  color: black;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2.5vmin);
}

.App-footer {
  margin-top: 10vh;

  > a.email {
    text-decoration: none;
    color: black;
    font-size: calc(2vmin);
  }
}

@media only screen and (min-width: 800px) {
  .App-header {
    font-size: calc(1.5vmin);
  }

  .App-footer {
    > a.email {
      font-size: calc(1vmin);
    }
  }
}